import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Ident, Subject } from '../../core.types';
import { Logger } from '../../services/logger.service';
import { PantheonService } from '../../services/pantheon.service';
import { LoadIdentsAction, LoadSubjectsAction } from './pantheon.actions';

const PANTHEON_STATE_NAME = 'pantheon';

export interface PantheonStateModel {
  subjects: Subject[];
  idents: Ident[];
}

@State<PantheonStateModel>({
  name: PANTHEON_STATE_NAME,
  defaults: {
    subjects: [],
    idents: [],
  },
})
@Injectable()
export class PantheonState {
  private log = new Logger('PantheonState');

  constructor(private pantheonService: PantheonService) {}

  @Selector()
  static getSubjectsList(state: PantheonStateModel) {
    return state.subjects;
  }

  @Selector()
  static getIdentsList(state: PantheonStateModel) {
    return state.idents;
  }

  @Selector()
  static getSubjectById(state: PantheonStateModel) {
    return (subjectId: string) =>
      state.subjects.find((subject) => subject.id === parseInt(subjectId, 10));
  }

  @Selector()
  static getIdentById(state: PantheonStateModel) {
    return (identId: string) =>
      state.idents.find((ident) => ident.ident === identId);
  }

  @Action(LoadSubjectsAction)
  loadSubjects({ patchState }: StateContext<PantheonStateModel>) {
    this.log.debug('load subjects');
    this.pantheonService.getSubjects().subscribe((subjects) => {
      patchState({ subjects });
    });
  }

  @Action(LoadIdentsAction)
  loadIdents({ patchState }: StateContext<PantheonStateModel>) {
    this.log.debug('load idents');
    return this.pantheonService.getIdents().subscribe((idents) => {
      patchState({ idents });
    });
  }
}
